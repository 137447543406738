<template>
	<keep-alive>
		<router-view v-if="$route.meta.keepAlive"></router-view>
	</keep-alive>
	<router-view v-if="!$route.meta.keepAlive"></router-view>
</template>

<script>
	export default {
		name: "App",
	};
</script>

<style lang="less">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	nav {
		padding: 20px;
		background-color: #2c3e50;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #67c2af;
			}
		}
	}
</style>
