<template>
	<img
		alt="Vue logo"
		style="width: 60px; height: 60px; padding: 5px"
		src="@/assets/dailychatIcon.png"
	/>

	<div>
		<a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
			<a-menu-item key="1">
				<pie-chart-outlined />
				<span>{{ $t("article_review") }}</span>
			</a-menu-item>
			<a-sub-menu key="2">
				<template #title>
					<span>
						<tags-outlined />
						<span>{{ $t("ad_code") }}</span>
					</span>
				</template>
				<a-menu-item key="21">{{ $t("ad_tips_get_code") }}</a-menu-item>
				<a-menu-item key="22">{{ $t("ad_tips_codes") }}</a-menu-item>
			</a-sub-menu>
			<!-- 版本管理 -->
			<a-sub-menu key="3">
				<template #title>
					<span>
						<desktop-outlined />
						<span>{{ $t("version_manager") }}</span>
					</span>
				</template>
				<a-menu-item key="31">{{ $t("version_force_update") }}</a-menu-item>
			</a-sub-menu>
			<!-- 版本管理 -->
			<a-sub-menu key="4">
				<template #title>
					<span>
						<user-outlined />
						<span>{{ $t("user_manager") }}</span>
					</span>
				</template>
				<a-menu-item key="41">{{ $t("user_manager_register") }}</a-menu-item>
			</a-sub-menu>
		</a-menu>
	</div>
</template>

<script>
	import {
		PieChartOutlined,
		DesktopOutlined,
		UserOutlined,
		TeamOutlined,
		FileOutlined,
		TagsOutlined,
	} from "@ant-design/icons-vue";
	import { ref } from "vue";
	export default {
		components: {
			PieChartOutlined,
			TagsOutlined,
			DesktopOutlined,
			UserOutlined,
			TeamOutlined,
			FileOutlined,
		},

		data() {
			return {
				selectedKeys: ref(["1"]),
			};
		},

		watch: {
			selectedKeys: {
				handler() {
					console.log(this.selectedKeys[0]);
					if (Number(this.selectedKeys[0]) === 1) {
						console.log("articlList");
						this.$router.push("/articl/articlList");
					} else if (Number(this.selectedKeys[0]) === 21) {
						this.$router.push("/ad/adCodeManager");
					} else if (Number(this.selectedKeys[0]) === 22) {
						this.$router.push("/ad/adList");
					} else if (Number(this.selectedKeys[0]) === 31) {
						this.$router.push("/version/versionManager");
					} else if (Number(this.selectedKeys[0]) === 41) {
						this.$router.push("/userManager/RegisterUsers");
					}
				},
			},
		},
	};
</script>

<style></style>
