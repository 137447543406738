<template>
	<a-layout style="min-height: 100vh">
		<a-layout-sider v-model:collapsed="collapsed" collapsible width="256px">
			<div class="logo" />
			<SiderMenu />
		</a-layout-sider>
		<a-layout>
			<a-layout-header class="headerStyle" style="padding: 0">
				<Header />
			</a-layout-header>
			<a-layout-content class="contentStyle" style="margin: 0 16px">
				<router-view v-slot="{ Component }">
					<keep-alive>
						<component
							:is="Component"
							:key="$route.name"
							v-if="$route.meta.keepAlive"
						></component>
					</keep-alive>
					<component
						:is="Component"
						:key="$route.name"
						v-if="!$route.meta.keepAlive"
					></component>
				</router-view>
			</a-layout-content>
			<a-layout-footer style="text-align: center">
				<Footer />
			</a-layout-footer>
		</a-layout>
	</a-layout>
</template>
<script>
	import Header from "./Header.vue";
	import Footer from "./Footer.vue";
	import SiderMenu from "./SiderMenu.vue";
	import { computed, ref } from "vue";

	export default {
		components: {
			Header,
			Footer,
			SiderMenu,
		},

		data() {
			return {
				collapsed: ref(false),
				selectedKeys: ref(["1"]),
			};
		},

		watch: {
			collapsed: {
				handler() {
					console.log(this.collapsed);
				},
			},
			selectedKeys: {
				handler() {
					console.log(this.selectedKeys);
				},
			},
		},

		computed: {
			selectMenuKey() {
				return this.$router.query.selectMenuKey || "1";
			},
		},
	};
</script>

<style lang="less">
	@import "./WebBasicLayout.less";

	.headerStyle {
		height: 60px;
		background-color: rgb(255, 255, 255);
	}
	.contentStyle {
		min-width: 800px;
	}
</style>
