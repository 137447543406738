module.exports = {
	jolly_register: "TutorJolly",
	jolly_register_invite_title: "注册领取",
	jolly_register_invite_reward1: "送您的",
	jolly_register_invite_reward2: "7天VIP",
	jolly_register_download_title: "下载领取",
	verification_ode: "验证邮箱",
	user_register: "用户注册",
	user_login: "用户登录",
	input_phone_number: "请输入邮箱",
	send_mail_tips: "验证码已发送您的邮箱，请注意查收！",
	input_mail_number_error: "邮箱格式错误",
	input_phone_number_error: "手机号错误",
	input_password_code: "请输入密码",
	show_password: "显示密码",
	hidden_password: "隐藏密码",
	input_password_tip: "密码大于6位",
	input_verification_code: "请输入邮件验证码",
	verification_error: "验证码错误",
	user_registered: "用户已注册",
	accpet_privacy: "请仔细阅读并同意协议",
	privacy_tips: "注册即代表同意以下协议",
	privacy_user: "《用户隐私协议》",
	privacy_register: "《用户注册协议》",
	register_success: "恭喜你注册成功，赶快下载领取奖励吧！",
	download_app: "下载App",
	error_tips: "出错啦，请重试一下",

	// web端
	web_login_user: "请输入帐号",
	web_login_passworld: "请输入密码",
	login_tip: "TutorJolly 后台管理",
	article_no: "序号",
	article_name: "文章名",
	article_language: "语言",
	article_type: "类型",
	article_status: "状态",
	article_action: "操作",

	//SiderMenu
	article_review: "文章审核",
	ad_code: "广告码管理",

	article_review_commit: "提交审核",
	article_review_success: "审核成功",
	article_review_failed: "审核失败",
	article_list: "文章列表",
	article_title: "审核标题",
	article_this_image: "请选择以上段落描述的正确的图片",
	article_option_is_right: "答案",
	article_section: "审核段落",
	article_hint: "审核提示",
	article_quest: "审核提问",
	article_quest_no: "提问",
	select: "审核以下选择题",
	article_quest_answer: "审核正确答案",
	article_quest_wrong_answer: "审核错误答案",
	article_review_back: "返回",
	article_image_refresh: "刷新图片",
	article_status_reviewed: "已审核",
	article_status_needReview: "未审核",
	article_status_undercarriage: "下架",

	//adManager
	ad_tips: "请输入免广告/VIP时间（天）,输入0表示赠送200红心！",
	ad_tips_commit: "生成",
	ad_tips_get_code: "生成广告码",
	ad_tips_codes: "广告码列表",

	ad_info_name: "广告码",
	ad_info_ex: "有效期(天)",
	ad_info_delete: "删除",

	ad_status_ok: "生效中",
	ad_status_failed: "失效",

	// 版本管理
	version_manager: "版本管理",
	version_force_update: "强制升级",
	version_limmit_version: "最小版本（小于此版本强制升级）设置格式: 1.0.0",

	setting: "设置",
	user_free_ad_code_success: "恭喜你获取{count}天免广告学习体验",
	user_free_heart_code_success: "恭喜你获取{count}个红心奖励",
	user_code_tips:
		"* User ID在TutorJolly个人中心获取 \n* 输入Ad Code获得红心奖励或者免费使用天数",

	//用户管理
	user_manager: "用户管理",
	user_manager_register: "注册用户统计",

	user_register_date: "注册时间",
	user_register_iOS: "iOS",
	user_register_Android: "Android",
	user_register_count: "新增用户数",
	user_register_total: "总计注册用户数：",
	user_register_yes: "昨日新增用户：",
	unsub_tips: "点击下方的“申请注销”按钮，即表示你已阅读并同意",
	delete_user: "注销用户",
	login: "登陆",

	unsub_text1: "注销账号须知:",
	unsub_text2:
		"1、注销账号是不可恢复的操作，您应自行备份账号相关的信息和数据。操作之前，请确认您的相关资产与服务已进行妥善处理。",
	unsub_text3:
		"2、一旦注销，该账号内已有的课程/金币/红心/学习记录/个人信息等均会一并注销删除且无法恢复。",
	unsub_text4:
		"3、注销提交申请后，请您退出该TutorJolly账号，并且不要再登录，避免生成新的TutorJolly账号。",
	unsub_text5: "4、注销申请立即生效。",
	unsub_success: "账户注销成功",
	no_user: "没有此用户或密码错误",
};
