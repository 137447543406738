<template>
	<div class="inputStyle">
		<a-input-search
			v-model:value="searchKey"
			placeholder="input search article title"
			enter-button
			@search="onSearch"
		/>
	</div>
	<a-table
		:columns="columns"
		:data-source="data.data"
		:pagination="false"
		:scroll="{ x: 1500, y: 1000 }"
		style="font-size: 18px"
		v-model="data"
		@change="pageChange"
	>
		<template #bodyCell="{ record, column }">
			<template v-if="column.key === 'operation'">
				<a-button
					type="primary"
					@click="showArticleDetails(record.lesson_id, record)"
					>{{ $t("article_review") }}</a-button
				>
			</template>
			<template v-if="column.key === 'status'"
				><a-dropdown>
					<template #overlay>
						<a-menu @click="handleMenuClick">
							<a-menu-item :key="record.lesson_id + '-2'">
								<UserOutlined />
								{{ $t("article_status_undercarriage") }}
							</a-menu-item>
						</a-menu>
					</template>
					<a-button>
						{{ this.getStatus(record.status) }}
						<DownOutlined />
					</a-button>
				</a-dropdown>
			</template>
		</template>
	</a-table>
	<a-pagination
		v-model:current="current"
		v-model:pageSize="pageSize"
		show-size-changer
		:total="data.total"
		@showSizeChange="onShowSizeChange"
	/>
</template>

<script>
	import { defineComponent } from "vue";
	import {
		getArticleDataList,
		editLessonStatus,
	} from "@/Http/Service/ArticleApi";
	import { message } from "ant-design-vue";
	import { DownOutlined } from "@ant-design/icons-vue";
	import EventBus from "@/store/event-bus";
	let vm = defineComponent({
		name: "articleList",

		components: {
			DownOutlined,
		},
		setup() {
			console.log("setup");
		},

		create() {
			console.log("create");
		},

		mounted() {
			console.log("mounted");

			EventBus.on("setArticleStatus", (val) => {
				console.log("==========", val.lessonId, val.status);
				this.data.data.forEach(function (item, index) {
					if (item.lesson_id === val.lessonId) {
						item.status = val.status;
					}
				});
			});
		},
		deactivated() {
			console.log("deactivated");
		},
		activated() {
			console.log("activated");
			if (!this.$route.meta.isBack) {
				this.loadArticleDataList(1);
			} else {
			}
			this.$route.meta.isBack = false; //请求完后进行初始化
		},

		//在页面离开时记录滚动位置
		beforeRouteLeave(to, from, next) {
			console.log("beforeRouteLeave");
			this.scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;

			next();

			next();
		},
		//进入该页面时，用之前保存的滚动位置赋值
		beforeRouteEnter(to, from, next) {
			console.log("beforeRouteEnter");

			if (from.name == "details") {
				// 这个name是下一级页面的路由name
				to.meta.isBack = true; // 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
			}
			next((vm) => {
				document.body.scrollTop = vm.scrollTop;
			});
		},

		props: {},

		data() {
			return {
				status: -1,
				level: "",
				searchKey: "",
				inputText: "",
				current: 1,
				pageSize: 10,
				data: {
					total: 0,
					data: [
						{
							language: "",
							lesson_id: "",
							lesson_level: "",
							lesson_no: "",
							lesson_title: "",
							lesson_category: "",
							status: "",
						},
					],
				},

				columns: [
					{
						title: "id",
						width: 0,
						dataIndex: "lesson_id",
						key: "name",
						fixed: "left",
						slots: { customRender: "lesson_id" }, //绑定插槽
					},
					{
						title: this.$t("article_no"),
						width: 20,
						dataIndex: "lesson_no",
						key: "name",
						fixed: "left",
					},
					{
						title: this.$t("article_name"),
						width: 50,
						dataIndex: "lesson_title",
						key: "age",
					},
					{
						title: this.$t("article_language"),
						width: 20,
						dataIndex: "language",
						key: "age",
					},
					{
						title: this.$t("article_type"),
						width: 20,
						dataIndex: "lesson_category",
						key: "age",
					},
					{
						title: "Level",
						filters: [
							{
								text: "L1",
								value: "L1",
							},
							{
								text: "L2",
								value: "L2",
							},
							{
								text: "L3",
								value: "L3",
							},
							{
								text: "L4",
								value: "L4",
							},
							{
								text: "L5",
								value: "L5",
							},
							{
								text: "L6",
								value: "L6",
							},
							{
								text: "L7",
								value: "L7",
							},
							{
								text: "L8",
								value: "L8",
							},
							{
								text: "L9",
								value: "L9",
							},
							{
								text: "L10",
								value: "L10",
							},
							{
								text: "L11",
								value: "L11",
							},
							{
								text: "L12",
								value: "L12",
							},
							{
								text: "L13",
								value: "L13",
							},
							{
								text: "L14",
								value: "L14",
							},
							{
								text: "L15",
								value: "L15",
							},
							{
								text: "L16",
								value: "L16",
							},
							{
								text: "L17",
								value: "L17",
							},
							{
								text: "L18",
								value: "L18",
							},
							{
								text: "L19",
								value: "L19",
							},
						],
						filterMultiple: false,
						width: 20,
						dataIndex: "lesson_level",
						key: "level",
					},
					{
						title: this.$t("article_status"),
						filters: [
							{
								text: this.$t("article_status_needReview"),
								value: "0",
							},
							{
								text: this.$t("article_status_reviewed"),
								value: "1",
							},
							{
								text: this.$t("article_status_undercarriage"),
								value: "2",
							},
						],
						filterMultiple: false,
						width: 20,
						dataIndex: "status",
						key: "status",
					},
					{
						title: this.$t("article_action"),
						key: "operation",
						fixed: "right",
						width: 20,
					},
				],

				paginatio: {},
			};
		},

		computed: {
			articleStatus: {
				set() {
					console.log(articleStatus);
				},
			},
		},

		watch: {
			current: {
				handler() {
					console.log("current", this.current);
					this.loadArticleDataList(this.current);
				},
			},
			pageSize: {
				handler() {
					this.loadArticleDataList(this.current);
					console.log(this.pageSize);
				},
			},
			status: {
				handler() {
					console.log("刷选状态：", this.status);
				},
			},
			level: {
				handler() {
					console.log("level：", this.level);
				},
			},
		},
		methods: {
			onSearch() {
				this.loadArticleDataList(1);
			},

			getStatus(status) {
				if (status === 2) {
					return this.$t("article_status_undercarriage");
				} else if (status === 1) {
					return this.$t("article_status_reviewed");
				} else {
					return this.$t("article_status_needReview");
				}
			},
			//
			pageChange(pagination, filters, sorter, { currentDataSource }) {
				console.log("=============paramsxxxxx", pagination, filters, sorter);
				if (filters.status) {
					this.status = parseInt(filters.status[0]);
				} else {
					this.status = -1;
				}

				if (filters.level) {
					this.level = filters.level[0];
				} else {
					this.level = "";
				}

				this.loadArticleDataList(1);
			},
			freshData() {
				console.log("freshData");
				this.loadArticleDataList(1);
			},

			//获取服务端数据
			loadArticleDataList(currentIndex) {
				getArticleDataList({
					status: this.status,
					level: this.level,
					title: this.searchKey,
					page: currentIndex,
					pageSize: this.pageSize,
				}).then((result) => {
					if (result.data.code === 200) {
						result.data.data.forEach(function (item, index) {
							if (item.lesson_level) {
								// this.levels.push({
								// 	text: item.lesson_level,
								// 	value: item.lesson_level,
								// });
							}
						});
						this.data = result.data;
						console.log("文章数据", this.data);
					} else {
						message.warning(this.$t("error_tips"));
					}
				});
			},

			showArticleDetails(articleId, model) {
				console.log("文章id", articleId);
				this.$router.push({
					path: "/articl/articleDetails",
					query: { lessonId: articleId, status: model },
				});
			},

			handleMenuClick(suatus) {
				console.log("=====", suatus.key);
				let lessonId = suatus.key.split("-")[0];
				let lessonStatus = suatus.key.split("-")[1];
				editLessonStatus({
					lesson_id: lessonId,
					lesson_status: lessonStatus,
				}).then((result) => {
					if (result.data.code === 200) {
						this.data.data.forEach(function (item, index) {
							console.log("文章数据", item, lessonId);
							if (item.lesson_id === parseInt(lessonId)) {
								item.status = 2;
							}
						});
					} else {
					}
				});
			},
		},
	});

	export default vm;
</script>

<style>
	.inputStyle {
		align-content: center;
		font-size: 24px;
		width: 70%;
	}
</style>
