import { createI18n } from "vue-i18n";
import zh from "./zhCN";
import en from "./enUS";

const i18n = createI18n({
  legacy: false,
  globalInjection: true, //全局生效$t
  // 如果本地有语言标识就采用本地，没有就根据浏览器语言默认标识显示语言
  // locale: localStorage.getItem("locale") || navigator.language.slice(0, 2),
  locale: "zh",
  messages: {
    zh,
    en,
  },
});

export default i18n;
