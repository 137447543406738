<template>
	<div class="header">
		<NaviView />
	</div>
</template>

<script>
	import NaviView from "@/components/NaviView.vue";
	import {
		HomeOutlined,
		SettingFilled,
		SmileOutlined,
		SyncOutlined,
		LoadingOutlined,
	} from "@ant-design/icons-vue";
	export default {
		components: {
			NaviView,
			HomeOutlined,
			SettingFilled,
			SmileOutlined,
			SyncOutlined,
			LoadingOutlined,
		},
	};
</script>

<style scoped>
	.header {
		height: 60px;
		float: right;
	}
</style>
