module.exports = {
  jolly_register: "TutorJolly",
  jolly_register_invite_title: "Register and receive:",
  jolly_register_invite_reward1: "Sent to you ",
  jolly_register_invite_reward2: "7 days VIP",
  jolly_register_download_title: "Download and receive:",
  verification_ode: "Verify",
  user_register: "Register",
  user_login: "User Login",
  input_phone_number: "Input mail",
  input_mail_number_error: "Invalid mailbox format",
  send_mail_tips: "The verification code has been sent to your email, please check it carefully!",
  input_phone_number_error: "Mail error",
  input_password_code: "Input password",
  show_password: "Show",
  hidden_password: "Hidden",
  input_password_tip: "Password greater than 6 digits",
  input_verification_code: "Input mail Code",
  verification_error: "User registered",
  user_registered: "Verification code error",
  accpet_privacy: "Please read and agree to the agreement carefully",
  privacy_tips: "By registering, one agrees to the following agreement",
  privacy_user: "<<User Privacy Agreement>>",
  privacy_register: "<<User Registration Agreement>>",
  register_success:
    "Congratulations on your successful registration. Download and receive rewards now!",
  download_app: "Download App",
  error_tips: "An error has occurred. Please try again",

  // web端
  web_login_user: "Please enter an account",
  web_login_passworld: "Please input a password",
  login_tip: "TutorJolly Management",
  article_no: "No",
  article_name: "Article name",
  article_language: "Language",
  article_type: "Type",
  article_status: "Status",
  article_action: "Action",
  //SiderMenu
  article_review: "Article review",
  ad_code: "AdCode Manager",
  
  article_review: "Article review",
  article_review_commit: "Commit",
  article_review_success: "Review success",
  article_review_failed: "Review failed",
  article_list: "Articles",
  article_title: "Review title",
  article_this_image: "Please select the correct picture described in the above paragraph",
  article_option_is_right: "answer",
  article_section: "Review Paragraph",
  article_hint: "Review hint",
  article_quest: "Review question",
  article_quest_no: "question",
  select: "Review the following multiple choice questions",
  article_quest_answer: "Review correct answers",
  article_quest_wrong_answer: "Review incorrect answers",
  article_review_back: "Back",
  article_image_refresh: "Refresh",

  article_status_reviewed: "Reviewed",
  article_status_needReview: "Unaudited",
  article_status_undercarriage: "Undercarriage",

  //adManager
  ad_tips: "Please enter the advertising free or VIP time (days), enter 0 to give away 200 hearts!",
  ad_tips_commit: "Commit",
  ad_tips_get_code: "Get ad code",
  ad_tips_codes: "Ad Codes",

  ad_info_name: "Ad Code",
  ad_info_ex: "Ex date(day)",
  ad_info_delete: "Delete",

  ad_status_ok:"In effect",
  ad_status_failed:"Lose efficacy",
  
  // 版本管理
  version_manager:"Versioning",
  version_force_update:"Force Upgrade",
  version_limmit_version:"Minimum version (mandatory upgrade below this version). eg: 1.0.0",

  setting:"Setting",

  user_free_ad_code_success: "Congratulations on obtaining the {count} day ad free learning mode",
  user_free_heart_code_success : "Congratulations on obtaining {count} hearts",
  user_code_tips : "* User ID obtained from TutorJolly's personal center\n* Enter Ad Code to receive Hearts rewards or free usage days",

  //用户管理
  user_manager:"User Management",
  user_manager_register:"Registered Users",

  user_register_date:"Registration time",
  user_register_iOS:"iOS",
  user_register_Android:"Android",
  user_register_count:"Number of registered users",
  user_register_total:"Total registered users：",
  user_register_yes:"New users added yesterday：",
  unsub_tips : "By clicking on the “Delete User” button below, you have read and agreed to it",
  delete_user : "Delete Account",
  login: "Login",

  unsub_text1: "Notice for canceling an account:",
  unsub_text2: "1.Canceling an account is an unrecoverable operation. You should back up the information and data related to your account. Before closing the account, ensure that your related assets and services are properly handled.",
  unsub_text3: "2.The courses, gold coins, hearts, learning records and personal information in the account will be canceled and deleted without recovery when you cancel the account.",
  unsub_text4: "3.After submitting the application, please log out of the TutorJolly account and do not log in again to avoid generating a new TutorJolly account.",
  unsub_text5: "4. The cancellation application takes effect immediately.",

  unsub_success: "Account delete successful.",
  no_user: "No such user or incorrect password.",
};
