import axios from "axios";
import md5 from "js-md5";
import qs from "qs";

//自动加在url前面
//debug
// axios.defaults.baseURL = "https://service.talkbo.cn/api/cms/";

// axios.defaults.baseURL = "https://api2.tutorjolly.com/api/cms/";
//release
axios.defaults.baseURL = "https://api.tutorjolly.com/api/cms/";

//超时时间
axios.defaults.timeout = 500000;
//跨域凭证
axios.defaults.withCredentials = false;

//响应和请求 拦截器配置
export default axios;
axios.interceptors.request.use(
  (config) => {
    const Timestamp = new Date().getTime()
    console.log(Timestamp);
    let token = $cookies.get("user_token")
    let commonParams = {
      timestamp: Timestamp,
      sign: md5(Timestamp + "esLkEsRy"),
      token:token,
    };
    console.log("默认参数",commonParams)
    console.log("请求带入参数data",config.data)
    console.log("请求带入参数params",config.params)
    if (config.params === undefined && config.data === undefined){
      console.log("没有输入参数", commonParams);
      config.data = commonParams;
      return config;
    } 
    let params = {}; // data参数

    if (config.params !== undefined){
      params = config.params
      params = Object.assign(commonParams, params);
    }
    if (config.data !== undefined){
      if (config.data.params){
        params = config.data.params
        params = Object.assign(commonParams, params);
      }
      }
      
    
    if (params === undefined){
      params = qs.parse(config.data.params)
      console.log("请求所有参数1",params)
    }

    const returnedTarget = Object.assign(commonParams, params);
    console.log("请求所有参数2",returnedTarget)

    config.params = returnedTarget;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//返回拦截
axios.interceptors.response.use(
  (res) => {
    console.log(res);
    console.log(res.data);
    if (typeof res.data !== "object") {
      console.log("返回数据不是对象！");
      return res;
    }
    if (res.data.code !== 200) {
      console.log("响应状态码" + res.data.code);
      return res;
    }
    return res;
  },
  (error) => {
    console.log("网络异常");
    Promise.reject(error);
  }
);
