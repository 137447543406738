import { createRouter, createWebHistory } from "vue-router";

import H5RegisterLayout from "../layouts/H5RegisterLayout"
import WebBaseLayout from "../layouts/WebBaseLayout"
import articleList from '@/views/webManager/articleReview/articleListView'


const routes = [

{
  path: '/user',
    component: H5RegisterLayout,
    redirect: '/user/registerForInvite?inviteCode=74266227&from=mTt',
    hidden: true,
    children: [
     
      {
        //需要参数?inviteCode=xxxxxx
        path: 'registerForInvite',
        name: 'RegisterU',
        component: () => import(/* webpackChunkName: "user" */ '@/views/H5Views/RegisterView')
      },
      {
        path: 'downloadApp',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/H5Views/RegisterSuccessView')
      },
      {
        path: 'code',
        name: 'code',
        component: () => import(/* webpackChunkName: "user" */ '@/views/H5Views/CodeView')
      },
      {
        path: 'deleteUser',
        name: 'deleteUser',
        component: () => import(/* webpackChunkName: "user" */ '@/views/H5Views/DeleteUserView')
      },
      {
        path: 'deleteUserConfirm',
        name: 'deleteUserConfirm',
        component: () => import(/* webpackChunkName: "user" */ '@/views/H5Views/DeleteUserConfirmView')
      },
      {
        path: 'DownloadTutorJollyApp',
        name: 'DownloadTutorJollyApp',
        component: () => import(/* webpackChunkName: "user" */ '@/views/H5Views/DownloadApp')
      },
      
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
},

{
  path: '/articl',
    component: WebBaseLayout,
    redirect: '/articl/articlList',
    hidden: true,
    children: [
     
      {
        path: 'articlList',
        name: 'articleList',
        meta: {
          keepAlive: true, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
         },
        component: articleList
      },
      {
        path: 'articleDetails',
        name: 'details',
        component: () => import(/* webpackChunkName: "articl" */ '@/views/webManager/articleReview/articleDetailsView')
      },
    ]
  
},


{
  path: '/user',
    component: WebBaseLayout,
    redirect: '/user/userList',
    hidden: true,
    children: [
     
      {
        path: 'userList',
        name: 'userList',
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
         },
         component: () => import(/* webpackChunkName: "articl" */ '@/views/webManager/user/userList')
      },
     
    ]
  
},

{
  path: '/pay',
    component: WebBaseLayout,
    redirect: '/pay/payList',
    hidden: true,
    children: [
     
      {
        path: 'payList',
        name: 'payList',
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
         },
         component: () => import(/* webpackChunkName: "articl" */ '@/views/webManager/payManager/payList')
      },
     
    ]
  
},

{
  path: '/ad',
    component: WebBaseLayout,
    redirect: '/ad/adCodeManager',
    hidden: true,
    children: [
     
      {
        path: 'adCodeManager',
        name: 'adCodeManager',
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
         },
         component: () => import(/* webpackChunkName: "articl" */ '@/views/webManager/ad/adCodeManager')
      },
      {
        path: 'adList',
        name: 'adList',
        meta: {
          keepAlive: true, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
         },
      
         component: () => import(/* webpackChunkName: "articl" */ '@/views/webManager/ad/adList')
      },
    ]
  
},

{
  path: '/version',
    component: WebBaseLayout,
    redirect: '/version/versionManager',
    hidden: true,
    children: [
     
      {
        path: 'versionManager',
        name: 'versionManager',
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
         },
         component: () => import(/* webpackChunkName: "articl" */ '@/views/webManager/version/versionManager')
      },
      
    ]
  
},

{
  path: '/userManager',
    component: WebBaseLayout,
    redirect: '/userManager/RegisterUsers',
    hidden: true,
    children: [
     
      {
        path: 'RegisterUsers',
        name: 'RegisterUsers',
        meta: {
          keepAlive: true, //该字段表示该页面需要缓存
         },
         component: () => import(/* webpackChunkName: "articl" */ '@/views/webManager/userManager/RegisterUsers')
      },
      
    ]
  
},


  {
    // path: "/login",
    path: "/",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/webManager/WebLoginView"),
  },
  
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to,from,next)=>{
  console.log("开始请求from", from, " ->to ", to, "next", next)

  let token = $cookies.get("user_token")
  if (token === null && to.path !== "/" 
  && to.path !== "/user/downloadApp" 
  && to.path !== "/user/registerForInvite" 
  && to.path !== "/user/DownloadTutorJollyApp" 
  && to.path !== "/user/code"
  && to.path !== "/user/deleteUser"
  && to.path !== "/user/deleteUserConfirm"
  && to.path !== "/user/deleteUserConform"
  && to.path !== "/privacy_policy_cn.html"
  && to.path !== "/privacy_policy_tw.html"
  && to.path !== "/privacy_policy.html"
  && to.path !== "/vip_cn.html"
  && to.path !== "/vip_en.html"
  && to.path !== "/vip_tw.html"
  ){
    console.log(token)
    console.log("非白名单")
    router.push("/")
    console.log("过期")
    return
  }

  next()
})

router.afterEach((to, from) => {
  console.log("afterEach from", from, " ->to ", to)


})

export default router;
