import request from "../Request";

//获取审核课程列表
export function getArticleDataList(params) {
  return request.get("getLessonList", { params });
}

//修改文章状态
export function editLessonStatus(params) {
  return request.post("editLesson", { params });
}

//获取课程详情
export function getArticleDetials(params) {
  return request.get("getJollyLessonDetail", { params });
}

//邀请注册
export function editArticle(params) {
  return request.post("editLesson", { params });
}

//邀请注册
export function refreshImage(params) {
  return request.post("updateImage", { params });
}