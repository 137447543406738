import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./resource/locale/index.js";
import VueCookies from 'vue-cookies'
import {GSignInButton} from 'vue-google-signin-button'
import eventBus from 'vue3-eventbus'
import mitt from 'mitt'


import {
  Image,
  Button,
  Input,
  Layout,
  Spin,
  Space,
  Checkbox, 
  Menu,
  Table,
  Affix,
  Radio,
  Message,
  Pagination,
  Breadcrumb,
  Dropdown,
  DatePicker,
} from "ant-design-vue";

// import { Icon }  from '@ant-design/icons-vue';
// import Antd from "ant-design-vue";
// import "ant-design-vue/dist/antd.css";

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(Button)
  .use(Dropdown)
  .use(Image)
  .use(Input)
  .use(Layout) 
  .use(Spin)
  .use(Space)
  .use(Checkbox)
  .use(Menu)
  .use(Breadcrumb)
  .use(Radio)
  .use(Affix)
  .use(Pagination)
  .use(i18n)
  .use(VueCookies)
  .use(Table)
  .use(GSignInButton,)
  .use(Message)
  .use(eventBus)
  .use(DatePicker)
  .use(mitt)
  .mount("#app");
export default app;
