<template>
  <div class="affixButton">
    <a-space>
      <a-button size="small" @click="change('zh')">中文</a-button>
      <a-button size="small" @click="change('en')">English</a-button>
      <a-button size="small"></a-button>
    </a-space>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    function change(type) {
      proxy.$i18n.locale = type;
    }
    const { t } = useI18n();
    return { change };
  },

  data: function () {
    return {
      return: {},
    };
  },
};
</script>

<style>
.affixButton {
  float: right;
}
</style>
